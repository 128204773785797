import logoEc from '../statics/logo_ec.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    <Container fluid className="justify-content-center text-light" style={{ paddingTop: '5vh', backgroundColor: '#0c2959', paddingBottom: '5vh' }} >

      <Row className="justify-content-center text-light" >
        <Col xs={ 10 } lg={ 3 } >
          <Row>
            <Col>
              <h2>A propos de nous</h2>
              <p>
                Nathalie LEFEVRE, expert-comptable inscrite au tableau
                de l'ordre des experts-comptables de Paris Ile de France.<br/>
                Nos locaux sont situés à Gometz la Ville, accessible
                facilement depuis Les Ulis comme depuis Limours.
              </p>
              <img src={ logoEc } alt="logo EC" width="100%" />
            </Col>
          </Row>
        </Col>

        <Col xs={ 10 } lg={ 3 } >
          <Row>
            <Col>
              <h2>Coordonnées de contact</h2>
              <p>
                Tel : <a href="tel:0184180066">01 84 18 00 66</a><br/>
                Fax : 09 72 29 43 65<br/>
                Email : <a href="mailto:contact@lefevre-ec.fr">contact@lefevre-ec.fr</a>
              </p>
            </Col>
          </Row>
        </Col>

        <Col xs={ 10 } lg={ 3 } >
          <Row>
            <Col>
              <h2>Adresse</h2>
              <p>
                Lefevre Expertise Comptable<br/>
                4 Rue de la boulaye<br/>
                91400, Gometz la Ville
              </p>
            </Col>
          </Row>
        </Col>

      </Row>
    </Container>

    <Container fluid className="text-light" style={{ paddingTop: '2vh', backgroundColor: '#444', paddingBottom: '0' }} >
      <Row className="text-light" >
        <Col>
          <p style={{ fontSize: '0.65rem' }} >
            commit: 02c85308 © 2014 - { new Date().getFullYear() } Lefevre Expertise Comptable. Tous droits réservés.
          </p>
        </Col>
        <Col className="text-right" style={{ fontSize: '0.65rem' }} >
          <a href="#top" > Haut de page  </a>/
          <a href="mailto:postmaster@lefevre-ec.fr" > Webmaster  </a>/
          <Link to="/legal" > Mentions légales  </Link>/
          <Link to="/partenaires" > Partenaires</Link>
        </Col>
      </Row>
    </Container>
  </>
);
}

export default Footer;
